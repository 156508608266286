
























import mixins from 'vue-typed-mixins'
import Section from '@/builder/sections/mixins/Section'

export default mixins(Section).extend({
  name: 'Background1',
  computed: {
    getImageWidth () {
      if (this.isMobile) {
        return '100%'
      }

      return (this.data.configuration.wb_image_width__number__ <= 1200)
        ? this.data.configuration.wb_image_width__number__ : 1200
    },
    getImageHeight () {
      return this.data.configuration.wb_image_height__number__
    }
  }
})
